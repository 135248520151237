import { TrackingService } from '../tracking/TrackingService';
import { Scarcity, TrackingPayload } from '../tracking/TrackingPayload';
import { TeaserSize } from '../tracking/TeaserSize';
import { countdownToMidnight } from '../util/countdownToMidnight';
import { UpTeaserType } from '../util/UpTeaserType';
import { Topic } from '../tracking/Topic';

export class TrackingUpTeaserService {

	constructor(
		private readonly trackingService: TrackingService
	) {
	}

	init() {
		const teaser = document.querySelector('.up-teaser-tracking');

		if (teaser) {
			this.trackTeaserView(teaser);
			if (!this.isSmallTeaser(teaser)) {
				this.trackTeaserSeen(teaser);
			}
			this.trackTeaserClicked(teaser);
			this.updateHeadline(teaser);
		}
	}

	private isSmallTeaser(teaser: Element) {
		return teaser.getAttribute('data-tracking-teaser-size') == 'MediaObject25';
	}

	private updateHeadline(teaser: Element) {
		const countdown = teaser.getAttribute('data-countdown');
		if (countdown == null || countdown === '0') {
			return;
		}

		const endTime = parseInt(countdown);
		const headlineElement = teaser.querySelector('.up_teaser_headline');

		if (isNaN(endTime) || !headlineElement) {
			return;
		}

		const headlinePrefix = headlineElement.textContent;
		const countdownCall = () => countdownToMidnight(endTime,
			`${headlinePrefix} in `, headlineElement,
			'Dein UP Plus ist abgelaufen');
		countdownCall();
		headlineElement['interval'] = window.setInterval(() => {
			countdownCall();
		}, 1000);
	}

	private trackTeaserView(teaser: Element) {
		const trackingContext = TrackingPayload.onTeaserView(
			<UpTeaserType>teaser.getAttribute('data-tracking-teaser-type'),
			<Scarcity>teaser.getAttribute('data-tracking-scarcity'),
			<TeaserSize>teaser.getAttribute('data-tracking-teaser-size')
		);

		const labelsForChildren = {};
		if (trackingContext.up_Scarcity !== undefined) {
			labelsForChildren['up_Scarcity'] = [trackingContext.up_Scarcity];
		}

		const parentLabels = {
			promo_Feature: ['true'],
			up_TeaserSize: [trackingContext.up_TeaserSize!],
			...this.getAggregationFeatureLabels(teaser)
		};

		const featureOrder = this.getFeatureOrder(teaser);
		if (!featureOrder) {
			return;
		}

		this.trackingService.sendFeatureTracking(
			Topic.AddFeaturesToPageImpression,
			'UPpromo_Teaser',
			'UPPromoTeaser',
			`UPPromoTeaser${trackingContext.up_TeaserType}`,
			'loaded',
			featureOrder,
			1,
			parentLabels,
			labelsForChildren
		);
	}

	private trackTeaserSeen(teaser: Element) {
		if (window.IntersectionObserver) {
			const observer = new IntersectionObserver((observerEntries, observer) => {

				const observerEntry = observerEntries[0];
				if (!observerEntry.isIntersecting) {
					return;
				}

				observer.unobserve(teaser);
				const trackingContext = TrackingPayload.onTeaserSeen(
					<UpTeaserType>teaser!.getAttribute('data-tracking-teaser-type'),
					<Scarcity>teaser.getAttribute('data-tracking-scarcity'),
					<TeaserSize>teaser!.getAttribute('data-tracking-teaser-size')
				);

				const labelsForChildren = {};
				if (trackingContext.up_Scarcity !== undefined) {
					labelsForChildren['up_Scarcity'] = [trackingContext.up_Scarcity];
				}

				const parentLabels = {
					promo_Click: ['UpTeaser'],
					promo_Feature: ['true'],
					up_TeaserSize: [trackingContext.up_TeaserSize!],
					...this.getAggregationFeatureLabels(teaser)
				};

				const featureOrder = this.getFeatureOrder(teaser);
				if (!featureOrder) {
					return;
				}

				this.trackingService.sendFeatureTracking(
					Topic.AddActionToEvent,
					'UPpromo_Teaser',
					'UPPromoTeaser',
					`UPPromoTeaser${trackingContext.up_TeaserType}`,
					'visible',
					featureOrder,
					1,
					parentLabels,
					labelsForChildren,
					'scroll'
				);
			}, { threshold: 0.75 });

			observer.observe(teaser);
		}
	}

	private trackTeaserClicked(teaser: Element) {
		teaser.addEventListener('click', () => {
			const trackingContext = TrackingPayload.onTeaserOpened(
				<UpTeaserType>teaser!.getAttribute('data-tracking-teaser-type'),
				<Scarcity>teaser.getAttribute('data-tracking-scarcity'),
				<TeaserSize>teaser!.getAttribute('data-tracking-teaser-size')
			);

				const labelsForChildren = {};
				if (trackingContext.up_Scarcity !== undefined) {
					labelsForChildren['up_Scarcity'] = [trackingContext.up_Scarcity];
				}

				const parentLabels = {
					promo_Click: ['UpTeaser'],
					promo_Feature: ['true'],
					up_TeaserSize: [trackingContext.up_TeaserSize!],
					...this.getAggregationFeatureLabels(teaser)
				};

				const featureOrder = this.getFeatureOrder(teaser);
				if (!featureOrder) {
					return;
				}

				this.trackingService.sendFeatureTracking(
					Topic.AddActionToEvent,
					'UPpromo_Teaser',
					'UPPromoTeaser',
					`UPPromoTeaser${trackingContext.up_TeaserType}`,
					'clicked',
					featureOrder,
					1,
					parentLabels,
					labelsForChildren,
					'open'
				);
		});
	}

	private getFeatureOrder(container: Element) {
		const dataFeatureOrder = container.parentElement?.getAttribute('data-feature-order');
		if (!dataFeatureOrder) {
			return undefined;
		}
		return parseInt(dataFeatureOrder, 0);
	}

	private getAggregationFeatureLabels(container: Element) {
		const aggregationFeatureLabels = container.parentElement?.getAttribute('data-feature-tracking-labels');
		if (!aggregationFeatureLabels) {
			return {};
		}
		return JSON.parse(aggregationFeatureLabels);
	}
}
